export function criteriaList(){
    const criteria =
        [
            {
                id: 1,
                title: 'Работоспособность прототипа',
                sections:
                    [
                        {
                            id: 1,
                            name: 'Видео-демонстрация продукта',
                            values: 3,
                            activeIndex: 0,
                        },
                        {
                            id: 2,
                            name: 'Завершённость (наличие функционирующего прототипа, доступность продукта пользователям и судьям)',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 3,
                            name: 'Дизайн',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 4,
                            name: 'Удобство интерфейса для всех категорий',
                            values: 3,
                            activeIndex: 0,
                        },
                    ]
            },
            {
                id: 2,
                title: 'Соответствие функциональным требованиям',
                sections:
                    [
                        {
                            id: 5,
                            name: 'Соответствие поставленной в кейса задаче и проблеме',
                            values: 3,
                            activeIndex: 0,
                        },
                        {
                            id: 6,
                            name: 'Реализация фич',
                            values: 9,
                            activeIndex: 0,
                        },
                    ]
            },
            {
                id: 3,
                title: 'Презентация',
                sections:
                    [
                        {
                            id: 7,
                            name: 'Соответствие структуре',
                            values: 3,
                            activeIndex: 0,
                        },
                        {
                            id: 8,
                            name: 'Презентация функционирующего прототипа',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 9,
                            name: 'Раскрытие сущности проекта',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 10,
                            name: 'Качество публичного выступления',
                            values: 3,
                            activeIndex: 0,
                        },
                    ]
            },
            {
                id: 4,
                title: 'Технологичность',
                sections:
                    [
                        {
                            id: 11,
                            name: 'Работоспособность и качество кода',
                            values: 5,
                            activeIndex: 0,
                        },
                        {
                            id: 12,
                            name: 'Использование технологии (количество, сложность)',
                            values: 3,
                            activeIndex: 0,
                        },
                        {
                            id: 13,
                            name: 'Количество ЯП, доступных в заданиях',
                            values: 3,
                            activeIndex: 0,
                        },
                        {
                            id: 14,
                            name: 'Безопасность исполнения кода',
                            values: 3,
                            activeIndex: 0,
                        },
                    ]
            },
            {
                id: 5,
                title: 'Потенциал',
                sections:
                    [
                        {
                            id: 15,
                            name: 'Возможность реализации проекта и выхода его на рынок в рамках 1 года, проект не содержит сложных технических задач, рыночный потенциал должен соответствовать затраченным ресурсам',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 16,
                            name: 'Возможность интеграции автопроверки кода с другими LMS/MOOC платформами',
                            values: 3,
                            activeIndex: 0,
                        },
                        {
                            id: 17,
                            name: 'Ценность проекта (социальная значимость/коммерческая перспективность)',
                            values: 3,
                            activeIndex: 0,
                        },
                        {
                            id: 18,
                            name: 'Конкурентные преимущества',
                            values: 3,
                            activeIndex: 0,
                        },
                        {
                            id: 19,
                            name: 'Roadmap проекта',
                            values: 2,
                            activeIndex: 0,
                        },
                    ]
            },
        ];
    return criteria;


}