<script setup>
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
const store = useStore()
// запрос на role делаем каждый раз, не только при авторизации
const userData = ref([])

onMounted( ()=>{
  try {
    init()
  } catch (err) {
    alert ('APPLICATION ERROR')
  }
})

const init = async () => {
  const urlData =  process.env.VUE_APP_ADMIN_URL + "/api/userData.php"

  // userId.value получен при авторизации
  await axios
      .get(`${urlData}?token=${token.value}&id=${userId.value}`)
      .then(response => (userData.value = response.data))

  // обновить данные о пользователе
  store.commit('userModule/setUserData', userData.value)
  store.commit('menuModule/initMenu')
}

//----------------------------------------------------------------------
const token = computed( () => {return store.getters['authModule/token']})
const userId = computed( () => {return store.getters['authModule/userId']})

</script>
<template>
  <main class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col"><h3>О мероприятии</h3></div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">

            <div class="card card-outline card-info">
              <div class="card-header pt-3">
                <h4>Международный фестиваль по спортивному программированию «Код мира»</h4>
              </div>
              <div class="card-body">
                С 29 мая по 2 июня Севастополь станет площадкой международного фестиваля по спортивному программированию «Код мира».
                <br><br>
                Хакатон в дисциплине «Программирование продуктовое» пройдет с 29 мая по 2 июня. Мероприятие приурочено к 80-й годовщине освобождения от немецко-фашистских захватчиков.
                <br>В течение трех дней вам предстоит  работать над специальным кейсом от ведущей IT-компании «Т1».
                <br>Общий призовой фонд фестиваля — 1 800 000 рублей, с наградами для первых десяти победителей:<br><br>
                <ul>
                  <li>1 место — 400 000 рублей;</li>
                  <li>2 место — 300 000 рублей;</li>
                  <li>3 место — 250 000 рублей;</li>
                  <li>4 место — 200 000 рублей;</li>
                  <li>5 место — 150 000 рублей;</li>
                  <li>6-10 места — по 100 000 рублей.</li>
                </ul>
              </div>
            </div>

            <div class="card card-outline card-danger">
              <div class="card-header pt-3">
                <h4>Партнеры</h4>
              </div>

              <div class="card-body">

                <div class="row">
                  <div class="col-xl-6 col-sm-12 text-center">
                  <img
                      src="https://world-code.ru/storage/images/logo/logo_partner_3222190275_3843287867.png"
                      class="logo m-3 img-fluid"
                  >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://world-code.ru/storage/images/logo/logo_partner_2382592003_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://world-code.ru/storage/images/logo/logo_partner_2687333192_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://world-code.ru/storage/images/logo/logo_partner_1225890490_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<style scoped>
.logo{
  max-width: 350px;
  max-height: 200px;
  -webkit-filter: saturate(0%);
  filter: saturate(0%);
}
</style>