<script setup>
import TheCriteria from "@/components/Admin/TheCriteria";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {ref, onMounted, computed} from "vue";
import axios from "axios";
import {criteriaList} from "@/use/criteria-list";

//----------------------------------------------------------------------

const store = useStore()
const route = useRoute()
const results = ref([])
const resultsCount = ref(0)

// имена членов жюри для таблицы
const users = ref([])
// окно с описанием критерия
const isModalInfo = ref(false)
const isModalRating = ref(false)
const currentUserIdForChange = ref(0)

// номер критерия в окне
const sectionIndex = ref(0)
const criteriaIndex = ref(0)

const isLoading = ref(false)
const criteriaSum = ref([])
const criteriaAverage = ref([])

// рейтинг команды
const rating = ref(0)
const criteria = ref([])

// по критериям
const result01 = ref(0)
const result02 = ref(0)
const result03 = ref(0)
const result04 = ref(0)
const result05 = ref(0)
//----------------------------------------------------------------------
onMounted( ()=>{
  try {
    init()
  } catch (err) {
    alert ('APPLICATION ERROR')
  }
})

// eslint-disable-next-line no-undef
const props = defineProps({
  teamId:{
    type: String,
    required: true,
  },
  userId:{
    type: String,
    required: true,
  }
})

//----------------------------------------------------------------------
// число критериев
const getCriteriaCount = (criteria) => {
  let count = 0
  criteria.forEach((item) => {
    item['sections'].forEach(() => {
      count++
    })
  });
  return count
}

//----------------------------------------------------------------------

const init = async () => {
  let criteriaCount = 0
  isLoading.value = true
  criteria.value = criteriaList()
  // количество критериев
  criteriaCount = getCriteriaCount(criteria.value)

  const urlResults = process.env.VUE_APP_ADMIN_URL + "/api/results.php"
  const urlUsers = process.env.VUE_APP_ADMIN_URL + "/api/users.php"

  await axios
      .get(`${urlUsers}?token=${token.value}`)
      .then(response => (users.value = response.data))
      .then(
          await axios
              .get(`${urlResults}?teamId=${props.teamId}&token=${token.value}`)
              .then(response => (results.value = response.data))
      )
      .catch(error => alert(error));

  resultsCount.value = results.value.length

  if (resultsCount.value === 0) {
    // нет результатов
    setTimeout(() => isLoading.value = false, 3000);
  } else {

    // первоначальная инициализация значений
    for (let i = 0; i < criteriaCount; i++) {
      criteriaSum.value.push(0)
      criteriaAverage.value.push(0)
    }

    //criteriaSum.value = [0,0,0,0,0,0,0,0]
    //criteriaAverage.value = [0,0,0,0,0,0,0,0]

    // сумма баллов
    // в этой версии среднее не учитываем

    //console.log(results.value)

    results.value.forEach((item) => {
      for (let i = 0; i < criteriaCount; i++) {
        let number = 'criteria' + (i+1)
        criteriaSum.value[i] += +item[number]
      }
    });

    console.log(criteriaSum.value)

    isLoading.value = false
    // criteriaAverage.value = criteriaSum.value.map(item => item/resultsCount.value);
    // рейтинг команды
    //rating.value = criteriaAverage.value.reduce(function(a, b){ return a + b }, 0) / criteriaCount;
    rating.value = criteriaSum.value.reduce(function(a, b){ return a + b }, 0);

    criteriaSum.value.forEach(function(item, index) {
      if (index >= 0 && index <= 3) {
        result01.value += item
      }
      if (index >= 4 && index <= 5) {
        result02.value += item
      }
      if (index >= 6 && index <= 9) {
        result03.value += item
      }
      if (index >= 10 && index <= 13) {
        result04.value += item
      }
      if (index >= 14 && index <= 18) {
        result05.value += item
      }
    });

    /*
    console.log(result01.value)
    console.log(result02.value)
    console.log(result03.value)
    console.log(result04.value)
    console.log(result05.value)
    */

  }
}

// имя члена жюри по id ------------------------------------------------
const getUser = (id) => {
  let user = users.value.find(item => item.id == id);
  return user.name;
}

// оценил результаты пользователь?
const testUser = (id) => {
  return results.value.findIndex(item => item.userId == id)
}

const showModal = (x, y) => {
  criteriaIndex.value = x
  sectionIndex.value = y
  isModalInfo.value = true
}

/*
const editRating = (id) => {
  currentUserIdForChange.value = id
  isModalRating.value = true
}
*/

const updateRating = () => {
  isModalRating.value = false
  init()
  //location.reload();
}

//----------------------------------------------------------------------
const token = computed( () => {return store.getters['authModule/token']})
//const user = computed( () => {return store.getters['authModule/user']})

</script>
<template>
  <div>
    <h3 class="text-center">Результаты оценки команды</h3>

    <div class="card card-outline card-info mt-4">
        <div class="table-responsive mt-2 p-2">
          <table
              class="table table-hover"
          >
            <thead>

            <tr>
              <th style="width: 20%" class="border-0"></th>
              <th style="width: 7%" class="border-0"></th>
              <th class="border-0 text-center" colspan="9">Критерии оценок</th>
            </tr>

            <tr>
              <th style="width: 20%" class="border-0">Кто оценил</th>
              <th style="width: 7%" class="border-0 text-center">Дата</th>

              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(0, 0)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(0, 1)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(0, 2)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(0, 3)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(1, 0)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(1, 1)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(2, 0)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(2, 1)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(2, 2)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(2, 3)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(3, 0)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(3, 1)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(3, 2)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(3, 3)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(4, 0)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(4, 1)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(4, 2)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(4, 3)">?</div></th>
              <th class="border-0 text-center"><div class="criteria-info" @click="showModal(4, 4)">?</div></th>

            </tr>
            </thead>
            <tbody>

            <tr v-for="(item,index) in users" :key="index">

              <td class="border">
                  {{ getUser(item['id']) }}
              </td>

              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['created'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria1'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria2'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria3'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria4'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria5'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria6'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria7'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria8'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria9'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria10'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria11'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria12'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria13'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria14'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria15'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria16'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria17'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria18'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria19'] }}
                </div>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
        <h3 class="text-center m-4">Рейтинг команды: {{rating}}</h3>

        <p class="p-2"><b>в том числе:</b></p>
        <p class="pl-4">Работоспособность прототипа: <span class="result">{{ result01 }}</span></p>
        <p class="pl-4">Соответствие функциональным требованиям: <span class="result">{{ result02 }}</span></p>
        <p class="pl-4">Презентация: <span class="result">{{ result03 }}</span></p>
        <p class="pl-4">Технологичность: <span class="result">{{ result04 }}</span></p>
        <p class="pl-4">Потенциал: <span class="result">{{ result05 }}</span></p>

    </div>
  </div>

  <teleport to="body">
    <v-modal
        v-if="isModalInfo"
        :title="criteria[criteriaIndex]['title']"
        @close="isModalInfo = false"
    >
      <div class="modal-body">
        <p class="mt-3">{{ criteria[criteriaIndex]['sections'][sectionIndex]['name'] }}</p>
      </div>
    </v-modal>

    <v-modal
        v-if="isModalRating"
        title="Оценки команды"
        modalType = "modal-dialog-scrollable modal-lg"
        @close="updateRating()"
    >
      <div class="modal-body">
        <the-criteria
            :teamId = "route.params.id"
            :userId = "currentUserIdForChange"
            :key = "currentUserIdForChange"
        >
        </the-criteria>
      </div>
    </v-modal>

  </teleport>

</template>
<style scoped>

.criteria-info {
  border-radius:50%;
  cursor: pointer;
}

.criteria-info:hover {
  color:#fff;
  background-color: #80b5d3;
}

.result {
  font-size: 24px;
}

</style>