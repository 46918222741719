<script setup>
import {useStore} from "vuex";
import {ref, computed, onMounted} from "vue";
import axios from "axios";

//----------------------------------------------------------------------

const store = useStore()
const isLoading = ref(false)
const teams = ref([])
// команды пользвателя после применения прав на доступ (в этой версии нет)
const userTeams = ref([])
// запрос на role делаем каждый раз, не только при авторизации
const userData = ref([])
// результаты команд
const results = ref(0)
// количество критериев в списке
const criterias = ref(19)

//----------------------------------------------------------------------

onMounted( ()=>{
  try {
    init()
  } catch (err) {
    alert ('APPLICATION ERROR')
  }
})

//----------------------------------------------------------------------

const init = async () => {

  isLoading.value = true
  store.commit('menuModule/initMenu')

  const urlData =  process.env.VUE_APP_ADMIN_URL + "/api/userData.php"
  const urlTeams = process.env.VUE_APP_URL + "/api/teams"
  const urlResult =  process.env.VUE_APP_ADMIN_URL + "/api/results.php"

  // userId.value получен при авторизации
  await axios
    .get(`${urlData}?token=${token.value}&id=${userId.value}`)
    .then(response => (userData.value = response.data))

  if (userData.value) {
    await axios
        .get(`${urlTeams}/${token.value}/${userData.value['role']}`)
        .then(response => (teams.value = response.data))
        .then(
            await axios
                .get(`${urlResult}?token=${token.value}&id=${userData.value['id']}`)
                .then(response => (results.value = response.data))
        )
  }

  // обновить данные о пользователе
  store.commit('userModule/setUserData', userData.value)

  if (teams.value.data.length === 0) {
    // команд нет
    setTimeout(() => isLoading.value = false, 3000);

  } else {

    // команды загружены
    isLoading.value = false
    // для удобства и осталось от старой версии
    userTeams.value = teams.value.data
    // запишем полный список команд в стор
    // для работы вкладок
    store.commit('teamsModule/setTeams', userTeams.value)
  }
}

// рейтинг каждой команды
const createRating = (teamId) => {
  let criteriaSum = 0
  // количество решений жюри
  let juriMembersCount = 0
  let rating = 0
  // сумма баллов
  results.value.forEach((item) => {
    if (+item['teamId'] === teamId){
      juriMembersCount++
      for (let i = 0; i < criterias.value; i++) {
        let number = 'criteria' + (i + 1)
        criteriaSum += +item[number]
      }
    }
  });
  if (juriMembersCount > 0){
    //rating = criteriaSum / (juriMembersCount * criterias.value)
    rating = criteriaSum
  }
  return rating
}

const createRatingDetail = (teamId, x, y) => {
  let criteriaSum = 0
  // сумма баллов
  results.value.forEach((item) => {
    if (+item['teamId'] === teamId){
      for (let i = x; i <= y; i++) {
        let number = 'criteria' + (i + 1)
        criteriaSum += +item[number]
      }
    }
  });
  return criteriaSum
}

// проверяем, есть оценки у коменды или нет
// кроме суперадмина role=100
const testTeam = (teamId, userId) => {
  if (userData.value['role'] == 100){
    return true
  } else {
    let complete = false
    results.value.forEach((item) => {
      if (+item['teamId'] === +teamId && +item['userId'] === +userId) {
        complete = true
      }
    });
    return complete
  }
}

//----------------------------------------------------------------------
const token = computed( () => {return store.getters['authModule/token']})
const userId = computed( () => {return store.getters['authModule/userId']})
</script>

<template>

    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col"><h3>Команды <span v-if="userTeams.length > 0">({{ userTeams.length }})</span></h3></div>
        </div>
      </div>
    </section>

    <section class="content mt-2">
      <div class="container-fluid">
        <div class="row" v-if="userTeams">
          <div
              v-for="(team,index) in userTeams"
              :key="index"
              class=" col-md-6 col-sm-12 mb-2"
          >
            <router-link
                :to="'/team/' + team['original']['team_id']"
                class="card-link"
            >
              <div class="card">
                <div :class="['card-header', testTeam(team['original']['team_id'], userId) ? 'bg-completed' : 'bg-no-completed']">
                  <strong>{{  team['original']['team'] }}</strong>
                </div>
                <div class="card-body" style="min-height: 180px">
                  <div
                      class="team-rating"
                  >{{ createRating( team['original']['team_id'] ) }}</div>

                  <div class="d-flex justify-content-around p-2 m-3 bg-light border rounded">
                  <div class="result">{{ createRatingDetail( team['original']['team_id'], 0, 3) }}</div>
                  <div class="result">{{ createRatingDetail( team['original']['team_id'], 4, 5) }}</div>
                  <div class="result">{{ createRatingDetail( team['original']['team_id'], 6, 9) }}</div>
                  <div class="result">{{ createRatingDetail( team['original']['team_id'], 10, 13) }}</div>
                  <div class="result">{{ createRatingDetail( team['original']['team_id'], 14, 18) }}</div>
                  </div>

                  <strong>Задача:</strong><br>
                  {{ team['original']['Task'] }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div
              v-if="isLoading"
              class="spinner-border text-primary m-4" role="status"
          ></div>
        </div>
      </div>

    </section>

</template>
<style scoped>
.bg-completed{
  background-color: #80b5d3;
  color: #fff;
}
.bg-no-completed{
  background-color: #ff5858;
  color: #fff;
}
.team-rating {
  display: flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  width:75px;
  height:75px;
  border:3px solid #ff5858;
  border-radius:50%;
  margin: 0 auto 10px auto;
  color: #ff5858;
  font-size: 24px;
  font-weight: bold;
}
.team-rating-empty {
  display: flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  width:75px;
  height:75px;
  border:3px solid #c7c7c7;
  border-radius:50%;
  margin: 0 auto 10px auto;
  color: #c7c7c7;
  font-size: 24px;
  font-weight: bold;
}
.result {
  font-size: 24px;
}

</style>